
$black: #0a0d4a;
$lightblue: #00a0df;
$purple: rgb(111, 0, 169);
$black : #0a0d4a;
$green : rgb(121,202,185);


.questionBorder{
  border: 2px solid $green;
  padding:1em;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  border-top:none;
}
.question{
h3{
  font-size:1.2rem;
  padding:1.2rem;
  //border-radius top left and right
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color:white;
  //transform cap
  text-transform: uppercase;
  //background linear gradient left to right
  background: linear-gradient(
    90deg,
    rgba(111, 0, 169, 1) 0%,
    rgba(0, 160, 223, 1) 70%,
    #77CAB8 100%
  );
  margin-bottom:0;
  margin-left:0;
  text-align:center;

}
h4{
  font-size: 1.2rem;
}


}

.options{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

.disableClick{
  pointer-events: none;

}
.opacityFull{
  opacity: 1;
}
.opacityHalf{
  opacity: 0.5;
}

.showAnswerFeedback{
  background-image:  linear-gradient($green, $green)!important;
  color: $black;
  border: 2px solid $black;

}


}
.footnote{
  font-size:12px;
}

.submit{
  margin-top:2em;
  width:50%!important;
  text-align:center;
  justify-content:center;
  margin: 0 auto;
  margin-top: 1em;
  background-image: linear-gradient(grey, grey)!important;
}
.option{
  width:100%;
  background-image: linear-gradient($purple, $purple);
  color:white;
  margin-bottom:1rem;
  border-radius:1rem;
  padding:1.3rem;
  font-weight:800;
  text-align:left;
  border:none;
  font-size:1rem;
  display:flex;
  position:relative;
  align-items:center;

  span{
    max-width:100%;
    margin-left:1em;

    @media only screen and (max-width: 500px) {
      max-width:70%;
    }
    @media only screen and (max-width: 420px) {
  
  }
}

  img{
    width:30px;
    position:absolute;
    right:0;
    margin-right:1em;
  }

  .feedbackIcon{
    position: absolute;
    right: 0;
    margin-right: 1em;
    font-size: 2rem;
  }

  .iconCorrect {
    margin-right: .7em;
  }
 
}

