.TitleBlockBackground {
  h1 {
    position: absolute;
    margin-left: 4vw;
    font-size: 4vw;
    color: #fff;
    margin-top: 10vw;
    letter-spacing: .03rem;
    font-weight: bold;
  }

  img {
    max-width: 100%;
  }

  .gradLineTop {
    width: 100%;
    height: 3vw;
    background-image: linear-gradient(90deg, rgb(111, 0, 169) 0%, rgb(0, 160, 223) 70%, #77cab8 100%);
  }

  .gradLineBot {
    width: 100%;
    height: 3vw;
    background-image: linear-gradient(90deg, rgb(111, 0, 169) 0%, rgb(0, 160, 223) 70%, #77cab8 100%);
    margin-top: -4px;
  }
}