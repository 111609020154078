@use "../../styles.scss";


$black: #0a0d4a;
$lightblue: #00a0df;
$purple: rgb(111, 0, 169);
$black : #0a0d4a;


.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  .menu_item {
    margin-left: 0px;
    position: relative;
    text-align: left;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
      text-decoration: underline !important;
    }
    //on hover underline
  }
  //make a tag aligned with image
  a {
    background-repeat: no-repeat;
    color: white;
    width: 48%;
    flex-direction: row;
    align-items: flex-end;
    text-decoration: none;
    font-weight: bold;
    //linear gradient top to bottom 0% to 100%
    background-image: linear-gradient($purple, $black 100%);
    border-radius:1rem;
    margin-top:2rem;
    padding:0.2rem;
    &:hover {
      text-decoration: underline !important;
    }
    //first a border-right 0
    &:first-child {
      border-right: 0;
    }
  }
  span {
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
  img {
    width: 50px;
    vertical-align: middle;
    margin-left: 10px;
  }
  .safety {
    // margin-left: 0px !important;
  }
  .menu_item--text {
    text-align:center;
    font-size:0.75rem;

  }
  .inline {
    display: inline;
  }

  //if under 440px size then make .menu flex-direction column
  @media only screen and (max-width: 719px) {
    flex-direction: column;
    margin: 0;
    .menu_item {
      margin: 10px;
      width: 95%;
      img {
        margin-left: 20px;
      }
    }
  
  }
}

