$darkgreen: #7e2e7a;
$black: #0a0d4a;
$lightblue: #00a0df;
.EyebrowHeadlineText {
  /* background-image: url(../../assets/images/header-background.jpg); */
  text-align: left;

  img {
    margin-top: 0;
    // margin-bottom: 0;
    //above 720px
    min-width:100%;
    @media (min-width: 720px) {
      max-width: 100%;
    }

   
  }
  h3 {
    margin-left: 0;
    font-size: 22px;
  }
 
  h5 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }

 


  .Footnotes {
    text-align: left;
    .text {
      font-size: 12px;
    }

    sup {
      font-size: 0.4rem
    }
  }
  .GraphBullets {
    text-align: left;
    border: 3px solid #77CAB8;
    border-radius: 2em;
    h5 {
      margin-bottom: 1em;
      margin-top: 0;
    }
    //list item bullet points green
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 20px;
      padding:1em;
    }

    li {
      padding-left: 1em;
      text-indent: -1em;
      margin-top:1em;
    }

    li::before {
      content: "• ";
      color: $black; /* or whatever color you prefer */
      zoom: 1.3;
      margin-top:1em;
      // vertical-align: sub;
    }
  }
.WideImageBlock::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
img::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
  

  .WideImageBlock {
    text-align: left;
    margin-bottom: 1rem;
    
    @media (max-width: 719px) {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        -webkit-appearance: none;// you need to tweak this to make it available..
        width: 12px;
    }
    
    img::-webkit-scrollbar{
     
        -webkit-appearance: none;// you need to tweak this to make it available..
        width: 12px;
    

    }
    }
    &.img_wrapper_clicked {
      mask-image: none !important;
    }
    .f2{
      content: url("../../assets/img/f2.png");
    
    }
    .f3{
      content: url("../../assets/img/f3.png");
    
    }
    .f4{
      content: url("../../assets/img/f4.png");
    
    }
  
    .vid-thumb{
      content: url("../../assets/img/vid-thumb.png");
    
    }
    .f5{
      content: url("../../assets/img/f5.png");
    
    }
    .f6{
      content: url("../../assets/img/f6.png");
    
    }
    .f7{
      content: url("../../assets/img/f7.png");
    
    }


    .f8{
      content: url("../../assets/img/f8.png");
    
    }
    .f9{
      content: url("../../assets/img/f9.png");
    
    }













   
  }
}

.gordon-lam{
  background-image: url("../../assets/img/gordon-lam.png");
   width:100%;
   height:250px;
   object-fit: contain;
   background-repeat: round;
   margin-bottom:1em;
   @media only screen and (max-width: 500px) {
    height:180px
   }
   p{
    width:70%;
    margin-top:50px;
    padding:2em;
    color:white;
    float:right;
    @media only screen and (max-width: 500px) {
      margin-top: 20px;
      margin-right: 1em;
      padding:1em;
      font-size:0.8em;
     }
   }

 
 }