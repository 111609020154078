  .brightcove-react-player-loader {
    padding: 15px 0;

    .video-js {
      width: 100%;
      padding-top: 56.25%;
    }

    .vjs-big-play-button {
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }