$black: #0a0d4a;
$lightblue: #00a0df;
$purple: rgb(111, 0, 169);
$black: #0a0d4a;

body {
  margin: 0px;
}

scrollbar{
  width:20px;
}
.App {
  width: 100%;
}

hr {
  padding-top: 2px;
  width: 90%;
  padding-bottom: 6px;
  margin: 0;
  border: none;
  width: 100%;
  color: #333;
  /* old IE */
  background-image: linear-gradient(90deg,
      rgba(111, 0, 169, 1) 0%,
      rgba(0, 160, 223, 1) 70%,
      #77cab8 100%);
}

* {
  font-family: Verdana;
}

h1,
h2,
h3 {
  font-family: Verdana;
}

h2 {
  color: $black;
  font-size: 34px;
  margin-bottom: 0;
}

h3 {
  color: $black;
  margin-left: 20px;
  font-size: 28px;
}

h4 {
  color: black;
  font-size: 20px;
  margin-top: 0;
}

h5 {
  color: black;
  font-size: 16px;
  font-weight: 0;
}

p {
  margin-top: 0;
}

.content-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
  background: #f2f2f2;
  background-image: linear-gradient(to right, $black, $black);
}

a:not(.alt-link) {
  background-repeat: no-repeat;
  color: white;
  width: 47%;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
  font-weight: bold;
  background-image: linear-gradient($purple, $black);
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 0.6rem;

  &:hover {
    text-decoration: underline !important;
  }

  //first a border-right 0
  &:first-child {
    border-right: 0;
  }
}

i,
.make-inline {
  display: inline !important;
  margin-bottom: unset !important;
  white-space: nowrap !important;
  @media only screen and (max-width: 600px) {
    white-space: break-spaces!important;
  }


}

.old-thumb {
  background-image: url("./assets/img/more.png");
  min-height: 200px;
}

.alt-link {
  text-decoration: none;
  color: #003479;

  &:hover {
    text-decoration: underline !important;

  }

  .old-thumb {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font-size: 1.8em;
    margin-left: 20px;
    margin-top: 20px;
  }
}

.plinks {
  font-weight: 100 !important;

  a {
    text-decoration: none;
  }
}

sup {
  vertical-align: top;
  font-size: .7rem;
}
sub{
  vertical-align: middle;
}

@media screen and (max-width: 719px) {
  .content-scroll {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    overflow-y: hidden;
    overflow-x: scroll;
    background: #f2f2f2;
  }
}