.TitleWithIcon,
.TitleWithoutIcon {
  .gradient{
    background: linear-gradient(90deg,#6f00a9,#00a0df 70%,#77cab8);

    color: #fff;
    margin-bottom: 0;
    margin-left: 0;
    padding: 1.2rem;
    text-align: left;
    width:100%;

  }
  h3 {
    margin-left: 0;
  }
  text-align: left;

  display: flex;
  justify-content: flex-start;

  img {
    width: 45px;
    height: 45px;
    display: none;
    }
}

.TitleWithIcon {
  margin-top: 20px;
}
.TitleWithoutIconHR {
  margin-top: 3rem;
}
.TitleWithoutIcon {
  h3 {
    margin-left: 0px !important;
    margin-bottom: 0;
    margin-top: 2rem;
    font-size: 22px;
  }
}
