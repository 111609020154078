@use "../../styles.scss";
$darkgreen: #359942;
$lightgreen: #77CAB8;
$black: #0a0d4a;
$lightblue: #00a0df;

#isi,
#indications-isi,
#safety-isi,
#indications-isi-static,
#safety-isi-static {
  @media (max-width: 719px) {
    .isi-header-2,
    .isi-header-1 {
      font-size: 12px !important;
    }
  }
  overflow: hidden;
  position: fixed;

  background: #eeeeee;
  transition: top 0.2s ease;
  // width: 100%;
  //box shadow on top
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  //no box shadow on left
  height: 100%;
  z-index: 99999;

  b {
    color: $black;
  }
  p {
    font-size: 14px;
  }
  h4{
    background: linear-gradient(
      90deg,
      rgba(111, 0, 169, 1) 0%,
      rgba(0, 160, 223, 1) 70%,
      #77CAB8 100%
    );
  }

  .isi-header-1,
  .isi-header-2 {
    padding: 0.8rem;
    margin-bottom: 0.6rem;
    padding-left: 10px;
    color: transparent;
    color: white;


    font-size: 16px;
  }
  .isi-content__body-a,
  .isi-content__body-b {
    padding-left: 1rem;
    p {
      font-size: 14px;
    }
  }
  .safety-small {
    .isi-content__body-a {
      overflow: scroll;
    }
  }
  .footnote {
    font-size: 12px !important;

    sup {
      font-size: 0.4rem;
    }
  }
}

#safety-isi {
  z-index: 1;
}
#indications-isi {
  z-index: 9999999;
}
.plus {
  float: right;
  margin-right: 1em;
  color:black;
}

.minus {
  display: none;
  float: right;
  margin-right: 1em;
}
.open {
  .plus {
    display: none !important;
  }
  .minus {
    display: block !important;
  }
}

@media (max-width: 719px) {
  .isi-header-2,
  .isi-header-1 {
    font-size: 12px !important;
  }
}

#indications-isi-static {
  z-index: 9999999999 !important;
}
#safety-isi {
  z-index: 0 !important;
}
